.accordion-button {
  background-color: #fdaaaa !important;
  color: white !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}
  
.accordion-button:not(.collapsed) {
  background-color: #d42027 !important;
  color: white !important;
  line-height: 0 !important;
}

.btn-success {
  --bs-btn-bg: #d42027 !important;
  --bs-btn-border-color: #d42027 !important;
}

.btn:hover {
  background-color: #d42027 !important;
  border-color: #d42027 !important;
}

.btn.show {
  background-color: #d42027 !important;
  border-color: #d42027 !important;
}

.btn {
  --bs-btn-font-size: calc(1.3rem + .6vw) !important;
  @media (min-width: 1200px) {
    --bs-btn-font-size: 1.75rem !important;
  }
  --bs-btn-padding-x: 0rem !important;
  --bs-btn-padding-y: 0.2rem !important;
}



.downloadTitle{
  text-align: left;
  @media (min-width: 1400px) {
    text-align: center;
  }
}

.scrollButton{
  position: fixed;
  right: 10%; 
  bottom: 40px; 
  height: 60px; 
  font-size: 1rem; 
  z-index: 1; 
  cursor: pointer; 
  background-color: '#d42027';
}

.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
}

.otp-input {
  width: 100%;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
}

.roundedModal {
  border-radius: 15px;
}